import FeaturedList from "./FeaturedList";

function FeaturedArts({ arts }) {
  const featured = arts;
  console.log(featured);
  return (
    <div className="container sell-admin">
      <div className="row row-cols-1 row-cols-md-4 g-4 d-flex justify-content-center">
        {featured.map((featured) => (
          <FeaturedList featured={featured} key={featured.title} />
        ))}
      </div>
    </div>
  );
}

export default FeaturedArts;
