import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCurrency } from "./CurrencyContext";
import { getCurrency } from "../services/apiCurrency";

function FeaturedList({ featured }) {
  const { currency } = useCurrency();
  const [convertedPrice, setConvertedPrice] = useState(null);

  const imagePath = "http://capstonevelasquez.wd99p.com";

  useEffect(() => {
    async function getConverted() {
      const convertedData = await getCurrency(featured.price, currency);
      setConvertedPrice(convertedData.rates[currency]);
    }

    getConverted();
  }, [currency]);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const { title, artist, photo, categories, id } = featured;

  const { name } = artist;

  return (
    <div className="col-md-6 col-lg-3 " key={id}>
      <div className="card d-flex">
        <Link to={`/${categories}/${id}`} onClick={scrollToTop}>
          <div className="img-resize">
            <img
              // onMouseEnter={() => setImageTransition(true)}
              // onMouseLeave={() => setImageTransition(false)}
              src={`${imagePath}${photo}`}
              alt={title}
              className="card-img-top"
            />
          </div>
        </Link>
        <div className="card-body">
          <p className="card-title">
            <strong>Title </strong> : {title}
          </p>
          <p>
            <strong>Artist</strong> : {name}
          </p>

          <p>
            {currency === "GBP" && (
              <>
                <strong>Price : </strong> £ {convertedPrice}
              </>
            )}
            {currency === "USD" && (
              <>
                <strong>Price : </strong> $ {convertedPrice}
              </>
            )}
            {currency === "EUR" && (
              <>
                <strong>Price : </strong> € {convertedPrice}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default FeaturedList;
