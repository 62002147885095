import { useLoaderData } from "react-router-dom";
import { getArtistDetails } from "../services/apiArts";
import ArtistProfile from "./ArtistProfile";

function ListOfArtist() {
  const artist = useLoaderData();
  console.log(artist);

  return (
    <div className="container sell-admin ">
      <div className="container">
        <h4 className="admin-category my-4">List Of Artist</h4>
        <hr />
        {artist.map((artist) => (
          <ArtistProfile artist={artist} key={artist.id} />
        ))}
      </div>
    </div>
  );
}

export default ListOfArtist;

export async function loader() {
  const artist = await getArtistDetails();
  return artist.artist;
}
