function AuthorBio({ quote, featuredArtist, artist, profile }) {
  return (
    <div className="container">
      <h2 className="featured">Artist Overview</h2>
      <div className="row">
        <div className="col-md-12 col-lg-4 d-flex justify-content-center  align-items-center">
          <div className="artist-img">
            <img src={featuredArtist} alt="artist" />
          </div>
        </div>
        <div className="col-md-12 col-lg-8 d-flex justify-content-center align-items-end">
          <div>
            <div>
              <p>{profile}</p>
            </div>
            <div className="quote_info">
              <p className="fs-3">{quote}</p>
            </div>
            <div className="d-flex justify-content-end align-items-center ">
              <p className="fw-bold"> &#x2E3A;{artist}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthorBio;
