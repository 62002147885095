import { get } from "react-hook-form";
import toast from "react-hot-toast";

// const API_ARTS = "http://localhost:8000/arts";

//FETCH API
// export async function getArts() {
//   const res = await fetch(`${API_ARTS}`);
//   if (!res.ok) throw Error("Failed of Getting Arts");
//   const data = await res.json();
//   return data;
// }

//POST METHOD
// export async function getItems(newItems) {
//   try {
//     const res = await fetch(`${API_ARTS}`, {
//       method: "POST",
//       body: JSON.stringify(newItems),
//       mode: "cors",
//     });
//     if (!res.ok) throw Error();
//     const data = await res.json();
//     return data;
//   } catch {
//     throw Error("Failed to create selling Item");
//   }
// }

//FETCH API WITH ID
// export async function getDetailsArt(id) {
//   const res = await fetch(`${API_ARTS}/${id}`);
//   if (!res.ok) throw Error(`Couldn't find Arts Details ${id}`);
//   const data = await res.json();
//   return data;
// }

//API FROM LARAVEL

const API_URL = "http://capstonevelasquez.wd99p.com";

// const GET_ARTIST = "http://127.0.0.1:8000/api/artist/info";

export async function getArtistDetails() {
  const res = await fetch(`${API_URL}/api/artist/info`);
  if (!res.ok) throw Error("Failed to Get Data");
  const data = await res.json();
  return data;
}

// const GET_ARTS = "http://127.0.0.1:8000/api/arts";

export async function getAllArts() {
  const res = await fetch(`${API_URL}/api/arts`);
  if (!res.ok) throw Error("Failed to Get Data");
  const data = await res.json();
  return data;
}

// const GET_SINGLE_ARTIST = "http://127.0.0.1:8000/api/artist/info";

export async function getSingleArtist(id) {
  const res = await fetch(`${API_URL}/api/artist/info/${id}`);
  if (!res.ok)
    throw Error(`Failed to get Artist details with an id of:' ${id}`);
  const data = await res.json();
  return data;
}

export async function deleteArtist(id) {
  try {
    const res = await fetch(`${API_URL}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw Error();
    }
    const artist = await res.json();
    return artist;
  } catch {
    throw Error("Failed to Delete Task");
  }
}

//GET SINGLE ART FOR MODIFY
// const GET_SINGLE_ART = "http://127.0.0.1:8000/api/arts";

export async function getSingleArt(id) {
  const res = await fetch(`${API_URL}/api/arts/${id}`);
  if (!res.ok) throw Error("Couldnt Find Arts Details");
  const data = await res.json();
  return data;
}

export async function deleteArt(id) {
  try {
    const res = await fetch(`${API_URL}/api/arts/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    if (!res.ok) {
      throw Error();
    }
    const artist = await res.json();
    return artist;
  } catch {
    throw Error("Failed to Delete Task");
  }
}

// const GET_ALL_ARTS_ARTIST = "http://127.0.0.1:8000/api/all/arts";

export async function getAllArtsofArtist(id) {
  const response = await fetch(`${API_URL}/api/all/arts/${id}`);

  if (!response.ok) {
    throw new Error("Failed to All Arts Data");
  }
  const data = await response.json();
  return data;
}

// const BACKEND_API = "http://127.0.0.1:8000";

// export async function getUserInfo(id) {
//   const res = await fetch(`${BACKEND_API}/api/get/info/${id}`);
//   if (!res.ok) throw Error("Couldnt User Details");
//   const data = await res.json();
//   return data;
// }

//With Token

export async function getUserInfo(id) {
  const token = localStorage.getItem("access_token");
  if (!token) {
    throw new Error("No access token found");
  }

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(
      `${API_URL}/api/get/info/${id}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error("Failed to fetch user details");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user info:", error.message);
    throw error;
  }
}

//Edit user and Admin Info

export async function updateUserInfo(userId, formData) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      //ADD LATER
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    body: JSON.stringify(formData),
  };

  try {
    const response = await fetch(
      `${API_URL}/api/user/${userId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error("Failed to update user information");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating user information:", error);
    throw error;
  }
}

//AddTo cartrds
export async function addToCart(userId, artId) {
  try {
    const response = await fetch(`${API_URL}/api/add/cart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({ arts_infos_id: artId, users_id: userId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (response.status === 409) {
        throw new Error(errorData.message || "Item is already in the cart");
      }
      throw new Error(errorData.message || "Failed to add item to cart");
    }

    const data = await response.json();
    console.log("Item added to cart:", data.message);
    return data; // Optional return data if needed bycomponent
  } catch (error) {
    console.error("Error adding item to cart:", error.message);
    throw error;
  }
}

//Function to CartItem
export async function fetchCartItems(userId) {
  try {
    const response = await fetch(`${API_URL}/api/cart/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch cart items");
    }

    return await response.json(); // Assuming response.json() returns the cart items
  } catch (error) {
    console.error("Error fetching cart items:", error);
    throw error;
  }
}
