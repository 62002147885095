function FeaturedAuthor() {
  return (
    <div className="container highlight">
      <h2 className="mb-4 featured">Featured Artist</h2>
      <div className="row">
        <div className="col-md-12 col-lg-6 bg-body-secondary">
          <img
            src="./featured_author.png"
            alt="Featured Author"
            className="team-img"
          />
        </div>
        <div className="col-md-12 col-lg-6 author-info">
          <div className="px-3">
            <h3 className="mb-4 mt-4">Marize Hermano</h3>
            <p>
              Marize's paintings transport viewers to enchanting realms where
              imagination knows no bounds. Her unique blend of realism and
              fantasy infuses each artwork with a sense of wonder, inviting
              observers to explore the depths of their own imagination. As a
              featured artist, Luca continues to inspire audiences worldwide
              with her evocative masterpieces, leaving an indelible mark on the
              art world.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedAuthor;
