function Shipping() {
  return (
    <div className="container mb-5">
      <div className="row d-flex justify-content-space-around ">
        <div className="col-md-12 col-lg-4 d-flex align-items-center  justify-content-center my-4">
          <div className=" d-flex align-items-center me-2">
            <i className="bi bi-truck fs-2"></i>
          </div>
          <div className="line-shipping">
            <h6 className="m-0 p-0">FREE SHIPPING</h6>
            <p className="m-0 p-0">Always on the House</p>
          </div>
        </div>
        <div className="col-md-12 col-lg-4 d-flex align-items-center  justify-content-center my-4">
          <div className=" d-flex align-items-center me-2">
            <i className="bi bi-bank fs-2"></i>
          </div>
          <div className="line-shipping">
            <h6 className="m-0 p-0">Exclusively Bank Transaction</h6>
            <p className="m-0 p-0">Elevating Security with Bank Transactions</p>
          </div>
        </div>
        <div className="col-md-12 col-lg-4 d-flex align-items-center justify-content-center my-4">
          <div className=" d-flex align-items-center me-2">
            <i className="bi bi-shield-check  fs-2 "></i>
          </div>
          <div className="line-shipping">
            <h6 className="m-0 p-0">Package Delivered with Protection</h6>
            <p className="m-0 p-0">Item delivered securely and safely</p>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default Shipping;
