import { useLoaderData } from "react-router-dom";
import { getAllArts } from "../services/apiArts";
import CartCategories from "./CartCategories";
import CartItem from "./CartItem";
import Shipping from "./Shipping";

function ShoppingCart() {
  const arts = useLoaderData();
  const allArts = arts.data;
  return (
    <div className="container p-3">
      <h4 className="mb-5 mt-4 fw-bold">Your Shopping Cart</h4>
      <CartItem />
      <div className="my-5">
        <CartCategories arts={allArts} />
      </div>

      <Shipping />
    </div>
  );
}

export default ShoppingCart;
export async function loader() {
  const res = await getAllArts();
  return res;
}
