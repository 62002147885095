function ArtistQuotes({ quote, artist }) {
  return (
    <div className="container d-flex justify-content-center">
      <div className="row ">
        <p className="col-md-12 quotes-p">{quote}</p>
        <h5 className="mt-2 col-md-12 quotes-r"> &mdash;{artist}</h5>
      </div>
    </div>
  );
}

export default ArtistQuotes;
