import { Fragment, useEffect, useState } from "react";
import { fetchCartItems } from "../services/apiArts";
import { useCurrency } from "./CurrencyContext";
import { getCurrency } from "../services/apiCurrency";

function CartItem() {
  const { currency } = useCurrency();
  const [cartItems, setCartItems] = useState([]);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    async function fetchItems() {
      try {
        const items = await fetchCartItems(userId);
        setCartItems(items); // Update state with fetched cart items
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    }

    fetchItems();
  }, [userId]);

  const photo_path = "http://capstonevelasquez.wd99p.com";

  return (
    <div className="row g-3">
      <div className=" col-md-6 col-lg-7  shop-cart">
        <div className="cart-items">
          <p className="img-cart ps-2 fw-bold">Item</p>
          <p className="img-qty fw-bold">Price</p>
        </div>
        <div>
          {cartItems.map((art) => (
            <Fragment key={art.id}>
              <div className="cart-shop-details">
                <div className="img-cart d-flex">
                  <div className="cart-image-art">
                    <img
                      src={`${photo_path}${art.arts_info.photo}`}
                      alt={art.arts_info.title}
                    />
                  </div>
                  <div className="cart-info-art">
                    <p>{art.arts_info.title}</p>
                    <p>Medium: {art.arts_info.medium}</p>
                  </div>
                </div>
                <div className="img-qty">
                  <p className="fw-bold mt-1">
                    <span className="mx-2"> £</span> {art.arts_info.price}
                  </p>
                </div>
                <div className="img-x text-center">
                  <i className="bi bi-trash3 fs-5"></i>
                </div>
              </div>
              <hr />
            </Fragment>
          ))}
          <p className="text-center fw-bold my-4">
            <em>Your cart is ready and waiting for you.</em>
          </p>
        </div>
      </div>
      <div className="col-md-1"></div>
      <div className="col-md-5 col-lg-4 p-0">
        <div className="order-summary">
          <div className="cart-summary">
            <p>Order Summary</p>
          </div>
          <div className="subtotal">
            <p>Subtotal</p>
            <p>
              <span className="me-2"> £</span>

              {cartItems
                .reduce((acc, art) => acc + art.arts_info.price, 0)
                .toFixed(2)}
            </p>
          </div>
          <hr />
          <div className="subtotal">
            <p className="fw-bold">Order Total</p>
            <p>
              <span className="me-2"> £</span>
              {cartItems
                .reduce((acc, art) => acc + art.arts_info.price, 0)
                .toFixed(2)}
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center cart-check-out">
            <p className="m-0 text-black">
              <i className="bi bi-bag-check fs-6 me-2"></i> Place Order
            </p>
          </div>
          <p className="text-center">
            <i className="bi bi-shield-check"></i> Your Information is Safe
          </p>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
