import { useLoaderData } from "react-router-dom";
import FeaturedArts from "./FeaturedArts";
import { getAllArts } from "../services/apiArts";

function AdminPaintings() {
  const arts = useLoaderData();
  const artsData = arts.filter((art) => art.categories === "paintings");
  const totalCount = artsData.length;
  console.log(totalCount);
  return (
    <div className="container">
      <h4 className="my-4 admin-category">Paintings</h4>
      {/* <span className="fw-light fs-6">1 - 6 of 6 results</span> */}

      <FeaturedArts arts={artsData} />
    </div>
  );
}

export async function loader() {
  const arts = await getAllArts();
  console.log("All arts data : ", arts.data);
  return arts.data;
}

export default AdminPaintings;
