import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCurrency } from "./CurrencyContext";
import { getCurrency } from "../services/apiCurrency";
import { addToCart } from "../services/apiArts";
import toast from "react-hot-toast";

function ArtsList({ arts }) {
  const { currency, setCount, login } = useCurrency();
  const navigate = useNavigate();
  const [convertedPrice, setConvertedPrice] = useState(null);

  const photo_path = "http://capstonevelasquez.wd99p.com";

  useEffect(() => {
    async function getConverted() {
      const convertedData = await getCurrency(arts.price, currency);
      setConvertedPrice(convertedData.rates[currency]);
    }

    getConverted();
  }, [currency]);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  const {
    title,
    artist: { name },
    photo,
    id,
    categories,
  } = arts;

  const users_id = localStorage.getItem("userId");
  const arts_infos_id = id;

  const handleAddToCart = async (userid, artid) => {
    try {
      if (!login) {
        navigate("/Login");
        return;
      }

      await addToCart(userid, artid); // Pass userId and artId to addToCart function
      toast.success("Item added to cart");
      setCount((c) => c + 1);
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error(error.message);
    }
  };

  return (
    <div className="col-md-6 col-lg-3" key={id}>
      <div className="card">
        <Link to={`/${categories}/${id}`} onClick={scrollToTop}>
          <div className="img-resize">
            <img
              src={`${photo_path}${photo}`}
              alt="title"
              className="gallery-top"
            />
          </div>
        </Link>

        <div className="card-body">
          <p className="card-title fw-bold"> {title}</p>
          <p className="fs-6 artist-name"> {name}</p>
          <p className="fw-bold mt-1">
            {currency === "GBP" && `Price : £ ${convertedPrice}`}
            {currency === "USD" && `Price : $ ${convertedPrice}`}
            {currency === "EUR" && `Price : € ${convertedPrice}`}
          </p>
          <div className=" d-flex justify-content-center align-items-center mt-2">
            <button
              className="btn btn-secondary w-100 buttt "
              onClick={() => handleAddToCart(users_id, arts_infos_id)}
            >
              ADD TO CART
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArtsList;
