import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CarouselList from "./CarouselList";

function CartCategories({ arts }) {
  const data = arts;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="container ">
      <h4 className=" fw-bold">Discover More</h4>
      <div className="row">
        <Slider {...settings}>
          {data.length > 0 ? (
            data.map((arts) => <CarouselList arts={arts} key={arts.title} />)
          ) : (
            <p>No Image</p>
          )}
        </Slider>
      </div>
    </div>
  );
}

export default CartCategories;
