function Sort({ sorted, setSorted }) {
  return (
    <div className="container">
      <div className="d-flex justify-content-end align-items-center">
        <h5 className="mt-2 me-3">SORT BY</h5>
        <select
          value={sorted}
          onChange={(e) => setSorted(e.target.value)}
          className="selection"
        >
          <option value="Latest">Latest</option>
          <option value="low">Price : Low to High </option>
          <option value="high">Price : High to Low</option>
        </select>
      </div>
    </div>
  );
}

export default Sort;
