function AdminHome() {
  return (
    <div className="container-fluid">
      <div className="mb-3">
        <h4>Admin Dashboard</h4>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4 d-flex">
          <div className="card flex-fill border-0 illustration">
            <div className="card-body p-0 d-flex flex-fill">
              <div className="row g-0 w-100">
                <div className="col-8 align-self-center">
                  <div className="p-3 m-1">
                    <h4>Total Users</h4>
                    <p className="mb-0">100</p>
                  </div>
                </div>
                <div className="col-4 align-self-center text-center">
                  <i className="bi bi-people fs-1"></i>
                </div>
                <span className="text-center align-self-end p-2 bg-warning ">
                  Show Users
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4  d-flex">
          <div className="card flex-fill border-0 illustration">
            <div className="card-body p-0 d-flex flex-fill">
              <div className="row g-0 w-100">
                <div className="col-8 align-self-center">
                  <div className="p-3 m-1">
                    <h4>Total Uploaded Arts</h4>
                    <p className="mb-0">40</p>
                  </div>
                </div>
                <div className="col-4 align-self-center text-center">
                  <i className="bi bi-palette fs-1"></i>
                </div>
                <span className="text-center align-self-end p-2 bg-warning ">
                  Show Arts
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6  col-lg-4 d-flex">
          <div className="card flex-fill border-0">
            <div className="card-body py-4">
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                  <h4 className="mb-2">Orders</h4>
                  <p className="mb-2">Total Earnings</p>
                  <div className="mb-0">
                    <span className="badge text-success me-2">+9.0%</span>
                    <span className="text-muted">Since Last Month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card border-0">
        <div className="card-header">
          <h5 className="card-title">Users Shopping Cart Details</h5>
          {/* <h6 className="card-subtitle text-muted">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
            ducimus, necessitatibus reprehenderit itaque!
          </h6> */}
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Username</th>
                <th scope="col">Email</th>
                <th scope="col">Total Cart Items</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Kiel</td>
                <td>test@gmail.com</td>
                <td>3</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>test@gmail.con</td>
                <td>Haaaaay</td>
                <td>5</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td colspan="2">yah@gmail.com</td>
                <td>5</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminHome;
