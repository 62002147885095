import ArtistListOfArts from "./ArtistListOfArts";

function ArtistArts({ arts, displayArt }) {
  // const artistArtsOnly = featured.filter((arts) => arts.artist_name === artist);
  // const remainingArts = artistArtsOnly.filter(
  //   (arts) => arts.id !== currentArtistId
  // );

  // console.log(artistArtsOnly);
  // if (remainingArts.length === 0) {
  //   return null;
  // }

  function getFirstName(fullname) {
    if (!fullname) return "";
    const names = fullname.split(" ");
    return names[0];
  }
  console.log(arts);
  const { artist = {}, arts: artistArts = [] } = arts || {};

  const { name } = artist;

  const remainingArts = artistArts.filter((art) => art.id !== displayArt);
  console.log(remainingArts);
  console.log(artistArts);
  return (
    <div className="container">
      <h2 className=" featured mb-5">More on {getFirstName(name)} Art's</h2>
      <div className="row row-cols-1 row-cols-md-4 g-4 d-flex justify-content-center ">
        {remainingArts.map((arts) => (
          <ArtistListOfArts arts={arts} artist={name} key={arts.id} />
        ))}
      </div>
    </div>
  );
}

export default ArtistArts;
