import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useCurrency } from "./CurrencyContext";

function Login() {
  const { register, formState, handleSubmit } = useForm();
  const { errors } = formState;
  const navigate = useNavigate();
  const { setLogin } = useCurrency();

  const onSubmit = async (data) => {
    try {
      const response = await fetch(
        "http://capstonevelasquez.wd99p.com/api/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        throw new Error("Invalid credentials. Please try again.");
      }
      const resData = await response.json();
      const {
        access_token,
        token_type,
        user: { username, id, email, role },
      } = resData;

      localStorage.setItem("access_token", access_token);
      localStorage.setItem("token_type", token_type);
      localStorage.setItem("username", username);
      localStorage.setItem("userId", id);
      localStorage.setItem("email", email);
      if (role === "admin") {
        navigate("/dash");
      } else if (role === "user") {
        navigate("/");
      }

      toast.success(`Welcome, ${username}`);
    } catch (error) {
      console.error("Login error :", error);
      toast.error("Invalid credentials. Please try again.");
    }
  };

  return (
    <div className="login-form">
      <form className="px-3" method="POST" onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex login-field">
          <div className="icon-login">
            <i className="bi bi-person-circle"></i>
          </div>
          <div className="form-login">
            <input
              type="email"
              placeholder="Email"
              className="w-100 h-100 "
              {...register("email", {
                required: "This field is required",
                patter: {
                  value: /\S+@\S+\.\S+/,
                  message: "Please provide a valid email address",
                },
              })}
            />
            {errors?.email && (
              <div className="mt-1 text-danger">{errors.email.message}</div>
            )}
          </div>
        </div>
        <div className="d-flex login-field">
          <div className="icon-login">
            <i className="bi bi-shield-lock-fill"></i>
          </div>
          <div className="form-login">
            <input
              type="password"
              placeholder="password"
              className="h-100 "
              {...register("password", { required: "This field is required" })}
            />
            {errors?.password && (
              <div className="mt-1 text-danger">{errors.password.message}</div>
            )}
          </div>
        </div>
        <div className="text-end mb-4">
          <div>Forgot Password?</div>
        </div>
        <div className=" d-flex justify-content-center login-button">
          <button className="btn btn-secondary mb-3" type="submit">
            Sign In
          </button>
        </div>
        <p className=" text-center ">
          Dont Have an account? <NavLink to="/createaccount">Sign up</NavLink>
        </p>
        <hr />
        <p className="text-center">Or Sign in with</p>

        <div className=" d-flex justify-content-center align-items-center my-4">
          <span className="me-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="40"
              height="40"
              viewBox="0 0 48 48"
            >
              <path
                fill="#FFC107"
                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
              ></path>
              <path
                fill="#FF3D00"
                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
              ></path>
              <path
                fill="#4CAF50"
                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
              ></path>
              <path
                fill="#1976D2"
                d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
              ></path>
            </svg>
          </span>
          <span className="me-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="40"
              height="40"
              viewBox="0 0 48 48"
            >
              <path
                fill="#039be5"
                d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"
              ></path>
              <path
                fill="#fff"
                d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
              ></path>
            </svg>
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="40"
              height="40"
              viewBox="0 0 64 64"
            >
              <path d="M32 6C17.641 6 6 17.641 6 32c0 12.277 8.512 22.56 19.955 25.286-.592-.141-1.179-.299-1.755-.479V50.85c0 0-.975.325-2.275.325-3.637 0-5.148-3.245-5.525-4.875-.229-.993-.827-1.934-1.469-2.509-.767-.684-1.126-.686-1.131-.92-.01-.491.658-.471.975-.471 1.625 0 2.857 1.729 3.429 2.623 1.417 2.207 2.938 2.577 3.721 2.577.975 0 1.817-.146 2.397-.426.268-1.888 1.108-3.57 2.478-4.774-6.097-1.219-10.4-4.716-10.4-10.4 0-2.928 1.175-5.619 3.133-7.792C19.333 23.641 19 22.494 19 20.625c0-1.235.086-2.751.65-4.225 0 0 3.708.026 7.205 3.338C28.469 19.268 30.196 19 32 19s3.531.268 5.145.738c3.497-3.312 7.205-3.338 7.205-3.338.567 1.474.65 2.99.65 4.225 0 2.015-.268 3.19-.432 3.697C46.466 26.475 47.6 29.124 47.6 32c0 5.684-4.303 9.181-10.4 10.4 1.628 1.43 2.6 3.513 2.6 5.85v8.557c-.576.181-1.162.338-1.755.479C49.488 54.56 58 44.277 58 32 58 17.641 46.359 6 32 6zM33.813 57.93C33.214 57.972 32.61 58 32 58 32.61 58 33.213 57.971 33.813 57.93zM37.786 57.346c-1.164.265-2.357.451-3.575.554C35.429 57.797 36.622 57.61 37.786 57.346zM32 58c-.61 0-1.214-.028-1.813-.07C30.787 57.971 31.39 58 32 58zM29.788 57.9c-1.217-.103-2.411-.289-3.574-.554C27.378 57.61 28.571 57.797 29.788 57.9z"></path>
            </svg>
          </span>
        </div>
      </form>
    </div>
  );
}

export default Login;
