import { NavLink, Outlet } from "react-router-dom";

function User() {
  return (
    <div className="container login-container vh-100 d-flex ">
      <div className="container mt-2 d-flex justify-content-center align-items-center">
        <div className="row shadow-login">
          <div className=" col-md-6 col-lg-6 p-0 m-0">
            <div className="login-form">
              <NavLink
                to="/"
                className="d-flex align-items-center justify-content-center mb-md-0 me-md-auto link-body-emphasis text-decoration-none nav-user"
              >
                <img src="../logo.svg" alt="FindArts Logo" className="logo" />
              </NavLink>
              {/* <p className="py-2  text-center">Discover your Passion</p> */}
              <Outlet />
            </div>
          </div>

          <div className="col-md-6 m-0 p-0 col-lg-6 d-none d-md-block  ">
            <div className="login-image  ">
              <img src="../login_img.png" alt="painting" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
