import { useEffect, useRef, useState } from "react";
import ArtistQuotes from "./ArtistQuotes";
import { getArtistDetails, getSingleArt } from "../services/apiArts";
import toast from "react-hot-toast";
import { useLoaderData, useNavigate } from "react-router-dom";

function AdminModifyArts() {
  const artDetail = useLoaderData();
  const navigate = useNavigate();
  console.log(artDetail);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [artist, setArtist] = useState(null);
  const photo_path = "capstonevelasquez.wd99p.com";

  const [artsData, setArtsData] = useState({
    title: artDetail.title || "",
    size: artDetail.size || "",
    categories: artDetail.categories || "",
    price: artDetail.price || "",
    description: artDetail.description || "",
    medium: artDetail.medium || "",
    photo: artDetail.photo ? `${photo_path}${artDetail.photo}` : null,
    art_created_date: artDetail.art_created_date || "",
    artist_profiles_id: artDetail.artist_profiles_id || "",
  });

  const fileInput = useRef(null);
  function handleCancel() {
    navigate(-1);
  }
  useEffect(() => {
    fetchArtist();
  }, []);

  async function fetchArtist() {
    try {
      const data = await getArtistDetails();
      setArtist(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      toast.error("Failed to fetch artist details. Please try again.");
    }
  }

  function handleFileChange(e) {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setArtsData({ ...artsData, photo: file });
    } else {
      setArtsData({ ...artsData, photo: null });
    }
  }

  function handleToUpload() {
    fileInput.current.click();
  }

  function handleRemoveImage() {
    setArtsData({ ...artsData, photo: null });
    fileInput.current.value = "";
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Form validation logic can be added here

      const formData = new FormData();
      formData.append("title", artsData.title);
      formData.append("size", artsData.size);
      formData.append("categories", artsData.categories);
      formData.append("price", artsData.price);
      formData.append("description", artsData.description);
      formData.append("medium", artsData.medium);

      if (artsData.photo instanceof File) {
        formData.append("photo", artsData.photo);
      }

      formData.append("art_created_date", artsData.art_created_date);
      formData.append("artist_profiles_id", artsData.artist_profiles_id);

      const response = await fetch(
        `http://capstonevelasquez.wd99p.com/api/arts/${artDetail.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update arts");
      }
      setArtsData({ ...artsData, categories: formData.get("categories") });
      const data = await response.json();
      console.log(data); // Log response data for debugging
      navigate(`/admin/${formData.get("categories")}`);
      toast.success("Arts Updated Successfully");

      // Clear form fields after successful submission
      setArtsData({
        title: "",
        size: "",
        categories: "",
        price: "",
        description: "",
        medium: "",
        photo: null,
        art_created_date: "",
        artist_profiles_id: "",
      });

      // Clear file input field
      fileInput.current.value = "";
    } catch (error) {
      console.error("Error Updating Arts :", error);
      toast.error("Failed to update arts. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  }

  const quote =
    "Art enables us to find ourselves and lose ourselves at the same time";
  const artists = "Thomas Merton";

  return (
    <div className="sell-admin container">
      <form method="POST" onSubmit={handleSubmit}>
        <h4 className="artist-text my-3">Modify Art Information</h4>
        <div className="art-background">
          <div className="art-div">
            <div className="row-bot img-upload">
              <input
                type="file"
                id="photo"
                name="photo"
                accept="image/*"
                ref={fileInput}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div>
                <label htmlFor="photos" className="col label-img">
                  <div
                    className={!artsData.photo ? "img-view" : ""}
                    onClick={handleToUpload}
                  >
                    {!artsData.photo && (
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="label-upload-img">
                          <div className="ms-3">
                            <i className="bi bi-upload fs-3"></i>
                          </div>
                          <div className="mx-3">
                            <p className="mt-3">Upload Image</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={artsData.photo ? "img-view" : ""}>
                    {artsData.photo && (
                      <img
                        src={
                          artsData.photo instanceof File
                            ? URL.createObjectURL(artsData.photo)
                            : artsData.photo
                        }
                        alt="Uploaded"
                      />
                    )}
                    {artsData.photo && (
                      <div className="x-butt">
                        <i
                          className="bi bi-x fs-1 x-button"
                          onClick={handleRemoveImage}
                        ></i>
                      </div>
                    )}
                  </div>
                </label>
              </div>
            </div>
            <div className="row mt-2 row-bot">
              <label htmlFor="categories" className="col-md-6 label-of-arts">
                Artist
              </label>
              <div className="input-bot col-md-6">
                <select
                  id="categories"
                  name="categories"
                  className="selectArea form-control"
                  value={artsData.artist_profiles_id}
                  onChange={(e) =>
                    setArtsData({
                      ...artsData,
                      artist_profiles_id: e.target.value,
                    })
                  }
                >
                  <option value="">Select Artist</option>
                  {artist &&
                    artist.artist &&
                    artist.artist.map((artist, index) => (
                      <option key={index} value={artist.id}>
                        {artist.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="row title pb-2 row-bot">
              <label htmlFor="title" className="col-md-6 label-of-arts">
                Title
              </label>
              <div className="input-bot col-md-6">
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="gagi form-control"
                  placeholder="e.g. The Dawn"
                  value={artsData.title}
                  onChange={(e) =>
                    setArtsData({ ...artsData, title: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row row-bot">
              <label htmlFor="description" className="col-md-6 label-of-arts">
                Description
              </label>
              <div className="input-bot col-md-6">
                <textarea
                  type="text"
                  id="description"
                  name="description"
                  rows="3"
                  className="rowsss form-control"
                  placeholder="e.g. Through a diverse range of initiatives, including workshops.."
                  value={artsData.description}
                  onChange={(e) =>
                    setArtsData({ ...artsData, description: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 row-bot">
          <label htmlFor="categories" className="col-md-6 label-of-arts">
            Categories
          </label>
          <div className="input-bot col-md-6">
            <select
              id="categories"
              name="categories"
              className="selectArea form-control"
              value={artsData.categories}
              onChange={(e) =>
                setArtsData({ ...artsData, categories: e.target.value })
              }
            >
              <option value="">Select </option>
              <option value="sculptures">Sculpture</option>
              <option value="paintings">Paintings</option>
              <option value="photography">Photography</option>
            </select>
          </div>
        </div>
        <div className="row row-bot">
          <label htmlFor="medium" className="col-md-6 label-of-arts">
            Medium
          </label>
          <div className="col-md-6 input-bot">
            <input
              type="text"
              id="medium"
              name="medium"
              placeholder="e.g. Acrylic, Woodcut, Digital etc.,"
              className="selectArea form-control"
              value={artsData.medium}
              onChange={(e) =>
                setArtsData({ ...artsData, medium: e.target.value })
              }
            />
          </div>
        </div>
        <div className="row row-bot">
          <label htmlFor="size" className="col-md-6 label-of-arts">
            Size
          </label>
          <div className="input-bot col-md-6">
            <input
              type="text"
              className="form-control"
              id="size"
              name="size"
              placeholder="e.g. 20' x 40'"
              value={artsData.size}
              onChange={(e) =>
                setArtsData({ ...artsData, size: e.target.value })
              }
            />
          </div>
        </div>
        <div className="row row-bot">
          <label htmlFor="date_of_creation" className="col-md-6 label-of-arts">
            Date Created
          </label>
          <div className="col-md-6">
            <input
              required
              type="date"
              name="art_created_date"
              value={artsData.art_created_date}
              onChange={(e) =>
                setArtsData({ ...artsData, art_created_date: e.target.value })
              }
              className="form-control w-50"
            />
          </div>
        </div>
        <div className="row row-bot">
          <label htmlFor="price" className="col-md-6 label-of-arts">
            Price
          </label>
          <div className="col-md-6 input-bot">
            <input
              type="text"
              id="price"
              name="price"
              placeholder="£0.00"
              value={artsData.price}
              className="form-control"
              onChange={(e) =>
                setArtsData({ ...artsData, price: e.target.value })
              }
            />
          </div>
        </div>

        <hr />

        <div className="d-flex justify-content-end me-5 button-update mt-4">
          <button
            type="submit"
            className="btn btn-secondary me-3"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Updating...." : "Update"}
          </button>
        </div>
      </form>
      <div className="button-cancel">
        <button className="btn btn-warning" onClick={handleCancel}>
          Cancel
        </button>
      </div>

      <div className="quotes">
        <ArtistQuotes quote={quote} artist={artists} />
      </div>
    </div>
  );
}

export default AdminModifyArts;

export async function loader({ params }) {
  const art = await getSingleArt(params.artId);
  console.log(art);
  return art;
}
