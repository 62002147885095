import { useNavigate, useRouteError } from "react-router-dom";

function Error() {
  const navigate = useNavigate();
  const error = useRouteError();
  console.log(error);
  function handleHome() {
    navigate("/");
  }
  return (
    <div className="container mt-5 d-flex align-items-center">
      <h1 className="me-3">Something went wrong </h1>
      <button onClick={handleHome} className="btn btn-primary">
        Go back Home
      </button>
    </div>
  );
}

export default Error;
