function Hero() {
  return (
    <div className="container-fluid home-section">
      <div className="col-md-12">
        <div
          id="carouselExampleIndicators"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner ">
            <div
              className="carousel-item active home-carousel-1 "
              data-bs-interval="2500"
            >
              <div className="comment-text">
                <div className="row">
                  <div className="col">
                    <h1 className="carousel-text">
                      Where every brushstroke tells a story
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="carousel-item home-carousel-2"
              data-bs-interval="2500"
            >
              <div className="comment-text">
                <div className="row">
                  <div className="col">
                    <h1 className="carousel-text">
                      Immerse yourself in a world of artistic expression
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="carousel-item home-carousel-3"
              data-bs-interval="2500"
            >
              <div className="comment-text">
                <div className="row">
                  <div className="col">
                    <h1 className="carousel-text">
                      Where every visit leaves an indelible mark on the soul
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
