import { useLoaderData, useNavigate } from "react-router-dom";
import { getUserInfo, updateUserInfo } from "../services/apiArts";
import Shipping from "./Shipping";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useCurrency } from "./CurrencyContext";

function ProfileUser() {
  const data = useLoaderData();
  const { register, formState, handleSubmit, setValue } = useForm();
  const navigate = useNavigate();
  const { setUsers } = useCurrency();
  console.log(data);

  useEffect(() => {
    if (data) {
      setValue("username", data.username);
      setValue("email", data.email);
      setValue("address", data.address);
      setValue("birthday", data.birthday);
    }
  }, [data, setValue]);

  const onSubmit = async (formData) => {
    try {
      const updatedData = await updateUserInfo(data.id, formData);
      console.log("Updated Data:", updatedData);
      toast.success("Saved Information");
      localStorage.removeItem("email");
      localStorage.removeItem("username");
      localStorage.setItem("username", updatedData.user.username);
      localStorage.setItem("email", updatedData.user.email);
      setUsers(updatedData.user.username);
      navigate(`/profile/${data.id}`);
      console.log(updatedData);
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  return (
    <div className="container">
      <div className="profile-user-bg d-flex align-items-center">
        <div className="row">
          <div className="col-md-12 col-lg-6 user-covers">
            <div>
              <p className="greet">Hello {data.username}</p>
              <p className="h4">
                Your work is going to fill a large part of your life, and the
                only way to be truly satisfied is to do what you believe is
                great work. And the only way to do great work is to love what
                you do.
              </p>
              <span className="fs-6">
                -<em>Steve Jobs</em>
              </span>
            </div>
          </div>
          <div className=" col-md-6 col-lg-6"></div>
        </div>
      </div>
      <div className="container mb-5 p-3">
        <div className="row">
          <div className="col-lg-8 user-info-form p-4">
            <h4>Account Information</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-6">
                  <label className="fs-6 mt-4 mb-1">Username</label>
                  <input
                    className="form-control user-input"
                    placeholder="Jessica"
                    type="text"
                    {...register("username")}
                  />
                </div>

                <div className="col-lg-6">
                  <label className="fs-6 mt-4 mb-1">Email</label>
                  <input
                    className="form-control user-input"
                    placeholder="find@gmail.com"
                    type="email"
                    {...register("email")}
                  />
                </div>

                <div className="col-lg-6">
                  <label className="fs-6 mt-4 mb-1">Address</label>
                  <input
                    type="text"
                    className="form-control user-input"
                    placeholder="Southampton United Kingdom"
                    {...register("address")}
                  />
                </div>
                <div className="col-lg-6 mb-4">
                  <label className="fs-6 mt-4 mb-1">Birthday</label>
                  <input
                    className="form-control user-input"
                    placeholder="Southampton United Kingdom"
                    type="date"
                    {...register("birthday")}
                  />
                </div>
              </div>
              <button className="btn btn-secondary">UPDATE</button>
            </form>
          </div>
          <div className="col-lg-1 mb-3"></div>
          <div className="col-lg-3 user-info-form p-4 ">
            <div>
              <h4>Orders</h4>
            </div>
          </div>
        </div>
      </div>
      <Shipping />
    </div>
  );
}

export default ProfileUser;

export async function loader({ params }) {
  const data = await getUserInfo(params.usersId);
  return data;
}
