export async function getCurrency(amount, currency) {
  if (currency === "GBP") {
    return { amount: amount, rates: { GBP: amount } };
  }
  const res = await fetch(
    `https://api.frankfurter.app/latest?amount=${amount}&from=GBP&to=${currency}`
  );
  const data = await res.json();
  return data;
}
