function Quote() {
  return (
    <div className="container highlight">
      <div className="row quote_row">
        <div className="col-md-5">
          <div className="quote_img">
            <img src="pablo_picasso.png" alt="pablo picasso" />
          </div>
        </div>
        <div className="col-md-7 quote_info d-flex justify-content-center align-items-center">
          <div>
            <div>
              <p>
                Every child is an artist. The Problem is how to remain an artist
                once he grows up.
              </p>
            </div>
            <div>
              <h3>&#x2E3A; Pablo Picasso</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quote;
