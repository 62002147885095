import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCurrency } from "./CurrencyContext";
import { getCurrency } from "../services/apiCurrency";

function CarouselList({ arts }) {
  const { currency } = useCurrency();

  const [convertedPrice, setConvertedPrice] = useState(null);

  const photo_path = "http://capstonevelasquez.wd99p.com";
  const { title, artist, photo, id, categories, price } = arts;
  const { name } = artist;

  useEffect(() => {
    async function getConverted() {
      const convertedData = await getCurrency(price, currency);
      setConvertedPrice(convertedData.rates[currency]);
    }

    getConverted();
  }, [currency]);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div key={id}>
      <div className="card p-3">
        <div className="image-bookmark">
          <Link to={`/${categories}/${id}`} onClick={scrollToTop}>
            <div className="img-resize">
              <img
                src={`${photo_path}${photo}`}
                alt="title"
                className="gallery-top"
              />
            </div>
          </Link>
        </div>
        <div className="card-body">
          <p className="card-title fw-bold "> {title}</p>
          <p className="fs-6 artist-name"> {name}</p>
          <p className="fw-bold mt-1">
            {currency === "GBP" && `Price : £ ${convertedPrice}`}
            {currency === "USD" && `Price : $ ${convertedPrice}`}
            {currency === "EUR" && `Price : € ${convertedPrice}`}
          </p>
          {/* <div className="add-to-cart-item">
            <p>ADD TO CART</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default CarouselList;
