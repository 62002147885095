import React, { useState, useEffect, useRef } from "react";
import { NavLink, Outlet, useNavigate, useNavigation } from "react-router-dom";
import "../dashboard.css";
import Spinner from "./Spinner";
import toast from "react-hot-toast";

function Dashboard() {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [theme, setTheme] = useState(isLight() ? "light" : "dark");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropDownRef = useRef(null);
  const navigates = useNavigation();
  const isLoading = navigates.state === "loading";
  const navigate = useNavigate();
  useEffect(() => {
    toggleRootClass(theme);

    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [theme]);

  function toggleSidebar() {
    setSidebarCollapsed(!sidebarCollapsed);
  }

  function toggleTheme() {
    toggleLocalStorage();
    setTheme((currentTheme) => (currentTheme === "light" ? "dark" : "light"));
  }

  function toggleRootClass(currentTheme) {
    document.documentElement.setAttribute("data-bs-theme", currentTheme);
  }

  function toggleLocalStorage() {
    if (isLight()) {
      localStorage.removeItem("light");
    } else {
      localStorage.setItem("light", "set");
    }
  }

  function isLight() {
    return localStorage.getItem("light") === "set";
  }

  const toggleDropdown = () => {
    setDropdownOpen((drop) => !drop);
  };

  //logout

  function handleLogout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("token_type");
    localStorage.removeItem("username");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.removeItem("light");
    setTheme("light");
    toggleRootClass("light");
    navigate("/Login");
    toast.success("Logout Successfull");
  }

  return (
    <div>
      <div className="wrapper">
        <aside
          id="sidebar"
          className={`js-sidebar ${sidebarCollapsed ? "collapsed" : ""}`}
        >
          <div className="h-100 fixed">
            <div className="sidebar-logo">
              <img
                src="../logo_dashboard.svg"
                alt="FindArts Logo"
                className="logo"
              />
            </div>
            <ul className="sidebar-nav">
              <li className="sidebar-header">Admin Panel</li>
              <li className="sidebar-item">
                <NavLink to="/dash" className="sidebar-link">
                  <i className="fa-solid fa-list pe-2"></i>
                  Dashboard
                </NavLink>
              </li>
              <li className="sidebar-item">
                <a
                  href="#"
                  className="sidebar-link collapsed"
                  data-bs-target="#pages"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-file-lines pe-2"></i>
                  Upload
                </a>
                <ul
                  id="pages"
                  className="sidebar-dropdown list-unstyled collapse"
                  data-bs-parent="#sidebar"
                >
                  <li className="sidebar-item try-side">
                    <NavLink to="/sellart" className="sidebar-link">
                      Add Arts
                    </NavLink>
                  </li>
                  <li className="sidebar-item try-side">
                    <NavLink to="/addArtist" className="sidebar-link">
                      Add Artist
                    </NavLink>
                  </li>
                  {/* <li className="sidebar-item try-side">
                    <a href="#" className="sidebar-link">
                      Edit
                    </a>
                  </li> */}
                </ul>
              </li>
              <li className="sidebar-item">
                <a
                  href="#"
                  className="sidebar-link collapsed"
                  data-bs-target="#posts"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="bi bi-palette pe-2"></i>
                  Arts
                </a>
                <ul
                  id="posts"
                  className="sidebar-dropdown list-unstyled collapse"
                  data-bs-parent="#sidebar"
                >
                  <li className="sidebar-item try-side">
                    <NavLink to="/admin/paintings" className="sidebar-link">
                      Paintings
                    </NavLink>
                  </li>
                  <li className="sidebar-item try-side">
                    <NavLink to="/admin/photography" className="sidebar-link">
                      Photography
                    </NavLink>
                  </li>
                  <li className="sidebar-item try-side">
                    <NavLink to="/admin/sculptures" className="sidebar-link">
                      Sculpture
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="sidebar-item">
                <NavLink to="/artist/information" className="sidebar-link">
                  <i className="bi bi-person-lines-fill me-2"></i>
                  Artist Information
                </NavLink>
              </li>
              <li className="sidebar-item">
                <a
                  href="#"
                  className="sidebar-link collapsed"
                  data-bs-target="#auth"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="bi bi-pencil-square me-2"></i>
                  Modify
                </a>
                <ul
                  id="auth"
                  className="sidebar-dropdown list-unstyled collapse"
                  data-bs-parent="#sidebar"
                >
                  <li className="sidebar-item try-side">
                    <NavLink to="/list/artist" className="sidebar-link">
                      Artist Bio Editing
                    </NavLink>
                  </li>
                  <li className="sidebar-item try-side">
                    <NavLink to="/arts/edit" className="sidebar-link">
                      Artwork Adjustment
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* <li className="sidebar-item">
                <a
                  href="#"
                  className="sidebar-link collapsed"
                  data-bs-target="#auth"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa-regular fa-user pe-2"></i>
                  Auth
                </a>
                <ul
                  id="auth"
                  className="sidebar-dropdown list-unstyled collapse"
                  data-bs-parent="#sidebar"
                >
                  <li className="sidebar-item try-side">
                    <a href="#" className="sidebar-link">
                      Add new Admin
                    </a>
                  </li>
                  <li className="sidebar-item try-side">
                    <a href="#" className="sidebar-link">
                      Register
                    </a>
                  </li>
                </ul>
              </li> */}
              <li className="sidebar-header">User Administration</li>
              <li className="sidebar-item">
                <a
                  href="#"
                  className="sidebar-link collapsed"
                  data-bs-target="#multi"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-share-nodes pe-2"></i>
                  Admin Users
                </a>
                <ul
                  id="multi"
                  className="sidebar-dropdown list-unstyled collapse"
                  data-bs-parent="#sidebar"
                >
                  <li className="sidebar-item">
                    <a
                      href="#"
                      className="sidebar-link collapsed"
                      data-bs-target="#level-1"
                      data-bs-toggle="collapse"
                      aria-expanded="false"
                    >
                      Admin
                    </a>
                    <ul
                      id="level-1"
                      className="sidebar-dropdown list-unstyled collapse"
                    >
                      <li className="sidebar-item try-side">
                        <NavLink to="/add/admin" className="sidebar-link">
                          <i className="bi bi-person-fill-add fs-6 me-2"></i>{" "}
                          Add Administrator
                        </NavLink>
                      </li>
                      <li className="sidebar-item try-side">
                        <NavLink to="/admin/users" className="sidebar-link">
                          <i className="bi bi-people-fill fs-6 me-2"></i> Admin
                          Users
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </aside>
        <div className={`main ${sidebarCollapsed ? "collapsed" : ""}`}>
          <nav className="navbar dash-nav navbar-expand px-3 border-bottom d-flex justify-content-between w-100">
            <button
              className="btn"
              id="sidebar-toggle"
              type="button"
              onClick={toggleSidebar}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse navbar">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item dropdown" ref={dropDownRef}>
                  <button
                    className="nav-link dropdown-toggle"
                    onClick={toggleDropdown}
                  >
                    <img
                      src="../login_img.png"
                      className="avatar img-fluid rounded"
                      alt="logos"
                    />
                  </button>
                  <div
                    className={`dropdown-menu dropdown-menu-end profile-down ${
                      dropdownOpen ? "show" : ""
                    }`}
                    style={{ left: "-192%", minWidth: "160px" }}
                  >
                    <a href="#" className="dropdown-item">
                      <i className="bi bi-person me-1"></i> Profile
                    </a>

                    <button className="dropdown-item" onClick={handleLogout}>
                      <i className="bi bi-box-arrow-right me-1"></i> Logout
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
          <main className="content px-3 py-2">
            {isLoading ? <Spinner /> : <Outlet />}
          </main>
          <a href="#" className="theme-toggle" onClick={toggleTheme}>
            <i className="bi bi-moon"></i>
            <i className="bi bi-sun text-black"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
