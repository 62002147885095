import { useLoaderData } from "react-router-dom";
import { getAllArts } from "../services/apiArts";
import ArtsProfile from "./ArtsProfile";
import { useState } from "react";

function ArtsEdit() {
  const artist = useLoaderData();
  console.log(artist);

  const [sorted, setSorted] = useState("All");
  let sortedArts = [...artist];

  if (sorted === "paintings")
    sortedArts = artist.filter((arts) => arts.categories === "paintings");
  if (sorted === "photography")
    sortedArts = artist.filter((arts) => arts.categories === "photography");
  if (sorted === "sculptures")
    sortedArts = artist.filter((arts) => arts.categories === "sculptures");
  return (
    <div className="container sell-admin ">
      <div className="container">
        <div className="d-flex justify-content-between">
          <h4 className="admin-category my-4">List Of Arts</h4>
          <div className="d-flex align-items-center">
            <h5 className="me-3 w-100">SORT BY</h5>
            <select
              className=" form-select"
              value={sorted}
              onChange={(e) => setSorted(e.target.value)}
            >
              <option value="All">All</option>
              <option value="paintings">Painting</option>
              <option value="photography">Photography</option>
              <option value="sculptures">Sculptures</option>
            </select>
          </div>
        </div>
        <hr />
        {sortedArts?.map((artist) => (
          <ArtsProfile artist={artist} key={artist.id} />
        ))}
      </div>
    </div>
  );
}

export default ArtsEdit;
export async function loader() {
  const artist = await getAllArts();
  console.log(artist);
  return artist.data;
}
