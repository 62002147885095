import { useLoaderData } from "react-router-dom";
import { getAllArts } from "../services/apiArts";
import FeaturedArts from "./FeaturedArts";

function AdminPhotography() {
  const arts = useLoaderData();
  const artsData = arts.data.filter((art) => art.categories === "photography");
  const totalCount = artsData.length;
  console.log(totalCount);

  return (
    <div className="container">
      <h4 className="my-4 admin-category">Photography</h4>
      {/* <span className="fw-light fs-6">1 - 3 of 3 results</span> */}

      <FeaturedArts arts={artsData} />
    </div>
  );
}

export default AdminPhotography;

export async function loader() {
  const arts = await getAllArts();
  return arts;
}
