import { useLoaderData } from "react-router-dom";
import { getArtistDetails } from "../services/apiArts";

import ShowArtist from "./ShowArtist";

function ArtistOnly() {
  const artist = useLoaderData();
  console.log(artist);

  return (
    <div className="container sell-admin ">
      <div className="container">
        <h4 className="admin-category my-4">Artist Information</h4>

        {artist?.map((artist) => (
          <ShowArtist artist={artist} key={artist.id} />
        ))}
      </div>
    </div>
  );
}

export default ArtistOnly;

export async function loader() {
  const artist = await getArtistDetails();
  return artist.artist;
}
