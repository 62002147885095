import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";

function CreateAccount() {
  const { formState, handleSubmit, reset, register, getValues } = useForm();
  const { errors } = formState;

  async function onSubmit(data) {
    try {
      const res = await fetch(
        "http://capstonevelasquez.wd99p.com/api/create/user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!res.ok) {
        throw new Error("Failed to Add");
      }

      const adminData = await res.json();
      toast.success(adminData.message);
      reset();
    } catch (error) {
      console.error("Failed to add an Admin : ", error);
      toast.error("Failed to create Account");
    }
  }
  return (
    <div className="login-form">
      <form className="px-3" onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex login-field">
          <div className="icon-login">
            <i className="bi bi-person-circle"></i>
          </div>
          <div className="form-login">
            <input
              type="text"
              placeholder="Username"
              className="w-100 h-100"
              {...register("username", {
                required: "This field is required.",
              })}
            />
            {errors?.username && (
              <div className="mt-1 text-danger">{errors.username.message}</div>
            )}
          </div>
        </div>
        <div className="d-flex login-field">
          <div className="icon-login">
            <i className="bi bi-envelope-fill"></i>
          </div>
          <div className="form-login">
            <input
              type="email"
              placeholder="Email"
              className="w-100 h-100"
              {...register("email", {
                required: "This field is required.",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email address.",
                },
              })}
            />
            {errors?.email && (
              <div className="mt-1 text-danger">{errors.email.message}</div>
            )}
          </div>
        </div>
        <div className="d-flex login-field">
          <div className="icon-login">
            <i className="bi bi-shield-lock-fill"></i>
          </div>
          <div className="form-login">
            <input
              type="password"
              placeholder="password"
              className="h-100"
              {...register("password", {
                required: "This field is required.",
                minLength: {
                  value: 8,
                  message: "At least 8 characters.",
                },
              })}
            />
            {errors?.password && (
              <div className="mt-1 text-danger">{errors.password.message}</div>
            )}
          </div>
        </div>
        <div className="d-flex login-field">
          <div className="icon-login">
            <i className="bi bi-shield-lock-fill"></i>
          </div>
          <div className="form-login">
            <input
              type="password"
              placeholder="confirm password"
              className="h-100"
              {...register("confirmPassword", {
                required: "This field is required.",
                validate: (value) =>
                  value === getValues().password || "Passwords must match",
              })}
            />
            {errors?.confirmPassword && (
              <div className="mt-1 text-danger">
                {errors.confirmPassword.message}
              </div>
            )}
          </div>
        </div>

        <div className=" d-flex justify-content-center login-button">
          <button className="btn btn-secondary mb-3">Sign Up</button>
        </div>
        <p className=" text-center my-4">
          Already have an account? <NavLink to="/Login">Login</NavLink>
        </p>
      </form>
    </div>
  );
}

export default CreateAccount;
