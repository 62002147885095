import { useLoaderData } from "react-router-dom";
import { getAllArts } from "../services/apiArts";
import FeaturedArts from "./FeaturedArts";

function AdminScuptures() {
  const arts = useLoaderData();
  const artsData = arts.data.filter((art) => art.categories === "sculptures");
  const totalCount = artsData.length;
  console.log(totalCount);
  return (
    <div className="container">
      <h4 className="my-4 admin-category">Sculptures</h4>
      {/* <span className="fw-light fs-6">1 - 3 of 3 results</span> */}
      <FeaturedArts arts={artsData} />
    </div>
  );
}

export default AdminScuptures;
export async function loader() {
  const arts = await getAllArts();
  return arts;
}
