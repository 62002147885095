import { useLoaderData } from "react-router-dom";
import Hero from "./Hero";

import { getAllArts } from "../services/apiArts";
import FeaturedAuthor from "./FeaturedAuthor";
import Quote from "./Quote";
import Shipping from "./Shipping";
import CartCategories from "./CartCategories";

function Home() {
  const arts = useLoaderData();
  console.log(arts);
  return (
    <div>
      <Hero />
      <div className="my-5">
        <Quote />
      </div>

      <div className="mt-5">
        <CartCategories arts={arts.data} />
      </div>

      <FeaturedAuthor />
      <Shipping />
    </div>
  );
}

export async function loader() {
  const arts = await getAllArts();
  return arts;
}
export default Home;
