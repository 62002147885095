import AuthorBio from "./AuthorBio";
import {
  addToCart,
  getAllArtsofArtist,
  getDetailsArt,
  getSingleArt,
} from "../services/apiArts";
import { useFetcher, useLoaderData, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ArtistArts from "./ArtistArts";
import Shipping from "./Shipping";
import { useCurrency } from "./CurrencyContext";
import { getCurrency } from "../services/apiCurrency";
import toast from "react-hot-toast";

function ArtsInfo() {
  const arts = useLoaderData();
  const { currency, setCount, login } = useCurrency();
  const [convertedPrice, setConvertedPrice] = useState(null);
  const [artistArts, setArtistArts] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    async function getConverted() {
      const convertedData = await getCurrency(arts.data.price, currency);
      setConvertedPrice(convertedData.rates[currency]);
    }

    getConverted();
  }, [currency]);

  const photo_path = "http://capstonevelasquez.wd99p.com";
  const {
    title,
    photo,
    medium,
    size,
    description,
    id: artsId,
    art_created_date,
    artist: { name, photo_url, profile, quote, id },
  } = arts.data;

  useEffect(() => {
    async function getThatArts() {
      try {
        const data = await getAllArtsofArtist(id);
        setArtistArts(data);
      } catch (error) {
        console.error("Unable to get Arts", error);
      }
    }
    getThatArts();
  }, [id]);
  console.log(artistArts);

  //add to cart
  const users_id = localStorage.getItem("userId");
  const arts_infos_id = id;

  const handleAddToCart = async (userid, artid) => {
    try {
      if (!login) {
        navigate("/Login");
        return;
      }
      await addToCart(userid, artid); // Pass userId and artId to addToCart function
      toast.success("Item added to cart");
      setCount((c) => c + 1);
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error(error.message);
    }
  };

  return (
    <div className="container mt-5 p-3">
      <h2 className="featured mb-3"> Artwork Details</h2>
      <div className="row">
        <div className=" col-md-12 col-lg-6 art-img">
          <div className="art-img-size">
            <img src={`${photo_path}${photo}`} alt={title} />
          </div>
        </div>
        <div className=" col-md-12 col-lg-6  art-info">
          <div className="col">
            <div className="mt-5 ">
              <h4 className="fw-bold">{title}</h4>
              <p className="fw-medium">{name}</p>
              <p>
                {" "}
                <span className="fw-bold"> Medium</span>: {medium}
              </p>
              <p>
                <span className="fw-bold">Date Created</span> :{" "}
                {art_created_date}
              </p>
              <p>
                <span className="fw-bold">Size</span>: {size}
              </p>
              <h5 className="fw-bold">About the Art Piece</h5>
              <p> {description}</p>
            </div>
            <div className="d-flex justify-content-center   row p-2">
              <div className="mt-2 art-price col-md-12">
                <h4>
                  {currency === "GBP" && `Price : £ ${convertedPrice}`}
                  {currency === "USD" && `Price : $ ${convertedPrice}`}
                  {currency === "EUR" && `Price : € ${convertedPrice}`}
                </h4>
                <button
                  className="btn btn-secondary"
                  onClick={() => handleAddToCart(users_id, arts_infos_id)}
                >
                  <i className="bi bi-cart fs-5 me-2"></i> ADD TO CART
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5">
        <AuthorBio
          featuredArtist={`${photo_path}${photo_url}`}
          quote={quote}
          artist={name}
          profile={profile}
        />
      </div>
      <div className="more-on-arts">
        <ArtistArts arts={artistArts} displayArt={artsId} />
      </div>

      <div className="my-5">
        <Shipping />
      </div>
    </div>
  );
}

export async function loader({ params }) {
  const arts = await getSingleArt(params.itemId);
  console.log(arts);
  return arts;
}

export default ArtsInfo;
