import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { useCurrency } from "./CurrencyContext";
import toast from "react-hot-toast";

function NavBar() {
  const { currency, setCurrency, users, setLogins, login } = useCurrency();
  const { count, setCount } = useCurrency();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const dropDownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen((drop) => !drop);
  };

  //cart

  const handleCartClick = () => {
    if (login) {
      navigate("/cart");
    } else {
      navigate("/Login");
    }
  };
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    const userLoggedIn = localStorage.getItem("access_token") !== null;
    setIsLoggedIn(userLoggedIn);
    if (userLoggedIn) {
      const name = localStorage.getItem("username");
      setUsername(name);
      setLogins(true);
    }
  }, [userId, users]);

  function handleLogout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("token_type");
    localStorage.removeItem("username");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    setIsLoggedIn(false);
    setLogins(false);

    navigate("/Login");
    setUsername("");
    setCount(0);
    toast.success("Logout Successfull");
  }

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  const BACKEND_API = "http://capstonevelasquez.wd99p.com";
  const LARAVEL_API = "http://127.0.0.1:8000";
  //cart count
  useEffect(() => {
    async function fetchCartCount() {
      try {
        const response = await fetch(
          `${BACKEND_API}/api/cart/count/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setCount(data.count);
        } else {
          throw new Error("Failed to fetch cart count");
        }
      } catch (error) {
        console.error("Error fetching cart count:", error);
        toast.error("Failed to fetch cart count");
      }
    }

    if (userId) {
      fetchCartCount();
    }
  }, [userId, count]);

  return (
    <div className="container-fluid nav-bg sticky-top d-flex">
      <div className="container navbar-expand-xl navbar d-flex align-items-center">
        <NavLink
          to="/"
          onClick={scrollToTop}
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
        >
          <img src="../logo.svg" alt="FindArts Logo" className="logo" />
        </NavLink>

        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <NavLink
              to="/"
              end
              onClick={scrollToTop}
              className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis "
            >
              <img src="../logo.svg" alt="Logo" className="logo" />
            </NavLink>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-center  flex-grow-1 ">
              <li className="nav-item ">
                <NavLink
                  onClick={scrollToTop}
                  to="/"
                  className="nav-link mx-lg-2 "
                  aria-current="page"
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/paintings"
                  onClick={scrollToTop}
                  className="nav-link mx-lg-2"
                >
                  Paintings
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/photography"
                  onClick={scrollToTop}
                  className="nav-link mx-lg-2 "
                >
                  Photography
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/sculptures"
                  onClick={scrollToTop}
                  className="nav-link mx-lg-2"
                >
                  Sculpture
                </NavLink>
              </li>
            </ul>
            {/* <div className="nav-item d-flex align-items-center  me-2">
              <NavLink to="/dash" onClick={scrollToTop}>
                Dashboard
              </NavLink>
            </div> */}
            <div className="nav-link mx-lg-2 currency  me-2">
              <select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="GBP">GBP</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </select>
            </div>
            {isLoggedIn ? (
              <div className="navbar-collapsee navbare me-2">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item dropdown" ref={dropDownRef}>
                    <button
                      className="nav-link dropdown-toggle"
                      onClick={toggleDropdown}
                    >
                      <p>
                        <i className="bi bi-brush me-2 "></i>Hello, {username}
                      </p>
                    </button>
                    <div
                      className={`dropdown-menu dropdown-menu-end  nav-down  ${
                        dropdownOpen ? "show" : ""
                      }`}
                    >
                      <NavLink
                        to={`/profile/${userId}`}
                        className="dropdown-item"
                      >
                        <i className="bi bi-person me-1"></i> Profile
                      </NavLink>

                      <button className="dropdown-item" onClick={handleLogout}>
                        <i className="bi bi-box-arrow-right me-1"></i> Logout
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              // <NavLink
              //   to="/profile"
              //   onClick={scrollToTop}
              //   className="nav-link mx-lg-2"
              // >
              //   <div className="nav-item me-3">
              //     <div className="add-to-cart">
              //       <p>
              //         <i className="bi bi-brush"></i>Hello, {username}
              //       </p>
              //     </div>
              //   </div>
              // </NavLink>
              <NavLink
                to="/Login"
                onClick={scrollToTop}
                className="nav-link mx-lg-2"
              >
                <div className="nav-item me-3">
                  <div className="add-to-cart">
                    <p>
                      <i className="bi bi-box-arrow-in-right"></i> Login
                    </p>
                  </div>
                </div>
              </NavLink>
            )}

            <div className="add-to-cart  nav-item " onClick={handleCartClick}>
              <div className=" nav-item like-container">
                <p>
                  <i className="bi bi-cart2"></i>
                </p>
                <div className="like-item">
                  <span className="fw-bold">{count}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
  );
}

export default NavBar;
