function AdminUsers() {
  return (
    <div className="container admin-all">
      <h4 className="artist-text my-4">Admin List</h4>
      <div className="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Username</th>
              <th scope="col">Sex</th>
              <th scope="col">Birthday</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Created</th>
            </tr>
          </thead>
          <tbody>
            <tr className="table-hyt">
              <td>
                <div className="d-flex  align-items-center gap-2">
                  <div className="admin-list">
                    <img src="../arts/us.png" alt="avatar" />
                  </div>
                  <p>Caijing </p>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center admin-list">
                  <p>Female</p>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center admin-list">
                  <p>June 15, 1992</p>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center admin-list">
                  <p>caspajeve@gmail.com</p>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center admin-list">
                  <p>0961287303</p>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center admin-list">
                  <p>18 Hours Ago</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>
    </div>
  );
}

export default AdminUsers;
